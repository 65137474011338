import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const ObtainDataCode = ({CODIGO}) => {
  console.log("codigo:",CODIGO)
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_ARIZTIA+'obtaindatacode',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        CODIGO: CODIGO
      })
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      resolve(responsito.body)
    }else{
      resolve(null)
    }
  })
}
